<template>
  <basic-container>
    <avue-crud
      :option="option"
      :table-loading="loading"
      :data="data"
      :page.sync="page"
      :permission="permissionList"
      :before-open="beforeOpen"
      v-model="form"
      ref="crud"
      @row-del="rowDel"
      @search-change="searchChange"
      @search-reset="searchReset"
      @selection-change="selectionChange"
      @current-change="currentChange"
      @size-change="sizeChange"
      @refresh-change="refreshChange"
      @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
          type="primary"
          size="small"
          v-if="permissionList.addBtn"
          @click="showEdit('add')"
          >{{$t('btnAdd')}}
        </el-button>
        <el-button
          type="danger"
          size="small"
          v-if="permissionList.delBtn"
          @click="handleDelete('add')"
          >{{$t('btnDelete')}}
        </el-button>
      </template>
      <template slot-scope="scope" slot="menu">
        <el-button
          type="text"
          size="small"
          icon="el-icon-edit"
          v-if="permissionList.editBtn"
          @click="showEdit('edit', scope)"
          >{{$t('btnEdit')}}
        </el-button>
        <el-button
          type="text"
          size="small"
          icon="el-icon-delete"
          v-if="permissionList.editBtn"
          @click="rowDel('edit', scope.row)"
          >{{$t('btnDelete')}}
        </el-button>
      </template>
    </avue-crud>
    <el-dialog
      :title="isAdd? $t('btnAdd'): $t('btnEdit')"
      append-to-body
      :close-on-click-modal="false"
      :before-close="handleClose"
      :visible.sync="authDialog"
      width="700px"
      >
      <el-form :model="form"
        ref="form"
        inline
        size="small"
        :rules="rlues"
        label-width="80px">
        <el-form-item 
          label="队伍名称"
          prop="name">
          <el-input style="width: 193px" v-model="form.name"></el-input>
        </el-form-item>
        <!-- <el-form-item 
          label="上级队伍"
          prop="parentId">
          <select-tree
              :placeholder="``"
              :data="teamList"
              v-model="form.parentId"/>
        </el-form-item> -->
        <el-form-item 
          label="队伍成员"
          prop="memberIds">
          <select-tree
            :multiple="true"
            :data="memberList"
            v-model="form.memberIds"/>
        </el-form-item>
        <el-form-item 
          label="负责人"
          prop="leaderId">
          <el-select 
            v-model="form.leaderId"
            placeholder="请选择">
            <el-option
              v-for="item in memberList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <div></div>
        <el-form-item 
          label="备注">
          <el-input style="width: 480px" type="textarea" v-model="form.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button 
          @click="handleClose">
          {{$t('cancelText')}}
        </el-button>
        <el-button 
          :loading="saveLoading"
          type="primary"
          @click="submit">
          {{$t('submitText')}}
        </el-button>
      </span>
    </el-dialog>
  </basic-container>
</template>

<script>
import {
  getEmergencyranksList,
  getEmergencyranksDetail,
  addEmergencyranks,
  updateEmergencyranks,
  removeEmergencyranks
} from "@/api/system/emergencyranks";
import { getuserList } from "@/api/system/user";
import { mapGetters } from "vuex";

export default {
  name: 'projectAuth',
  data() {
    return {
      form: {
        parentId: '',
        name: '',
        memberIds: [],
        leaderId: '',
        remark: ''
      },
      rlues: {
        name: [
          { required: true, message: '输入不能为空', trigger: 'blur' },
        ],
        parentId: [],
        memberIds: [],
        leaderId: []
      },
      teamList: [],
      memberList: [],
      query: {},
      isAdd: true,
      loading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      option: {
        selection: true,
        searchBtnText: this.$t('crud.searchBtn'),
        searchBtnIcon: 'el-icon-search',
        emptyBtnText: this.$t('crud.emptyBtnText'),
        emptyBtnIcon: 'el-icon-delete',
        height:'auto',
        calcHeight: 30,
        tip: false,
        searchShow: true,
        searchMenuSpan: 6,
        border: true,
        index: true,
        viewBtn: true,
        editBtn: false,
        delBtn: false,
        addBtn: false,
        dialogClickModal: false,
        column: [
          {
            label: this.$t('user.emergencyranks.name'),
            prop: "name"
          },
          // {
          //   label: this.$t('user.emergencyranks.parentName'),
          //   prop: "parentName"
          // },
          {
            label: this.$t('user.emergencyranks.memberNames'),
            prop: "memberNames",
            overHidden: true
          },
          {
            label: this.$t('user.emergencyranks.memberNumber'),
            prop: "memberNumber"
          },
          {
            label: this.$t('user.emergencyranks.leaderName'),
            prop: "leaderName",
          },
          {
            label: this.$t('user.emergencyranks.remark'),
            prop: "remark"
          },
          {
            label: this.$t('updateTime'),
            prop: "updateTime"
          }
        ]
      },
      data: [],
      authDialog: false,
      saveLoading: false,
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: !!this.permission.emergencyranks_add,
        delBtn: !!this.permission.emergencyranks_delete,
        editBtn: !!this.permission.emergencyranks_edit,
      };
    },
  },
  mounted () {
    this.getuserList()
  },
  methods: {
    showEdit(type, node) {
      if (type === 'add') {
        this.isAdd = true
      } else {
        this.isAdd = false
        let data = JSON.parse(JSON.stringify(node.row))
        this.form = data
      }
      this.authDialog = true
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择至少一条数据");
        return;
      }
      let ids = this.selectionList.map(item=>{
        return item.id
      }).join(',')
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return removeEmergencyranks(ids);
        })
        .then(() => {
          // 刷新表格数据并重载
          this.data = [];
          this.parentId = 0;
          this.$refs.crud.refreshTable();
          this.$refs.crud.toggleSelection();
          // 表格数据重载
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    },
    submit() {
      this.$refs.form.validate((value)=>{
        if (value) {
          if (this.isAdd) {
            this.rowSave()
          } else {
            this.rowUpdate()
          }
        }
      })
    },
    rowSave() {
      this.saveLoading = true
      let form = JSON.parse(JSON.stringify(this.form))
      form.memberNames = this.memberList.filter(item=>{
        return this.form.memberIds.indexOf(item.value) > -1
      }).map(item=>{
        return item.label
      }).join(',')
      // form.parentName = this.teamList.filter(item=>{
      //   return item.value === form.parentId
      // }).map(item=>{
      //   return item.label
      // }).join(',')
      form.leaderName = this.memberList.filter(item=>{
        return item.value === form.leaderId
      }).map(item=>{
        return item.label
      }).join(',')
      addEmergencyranks(form).then(res => {
        if (res.data.code === 200) {
          this.onLoad(this.page);
          this.handleClose()
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        }
      }).finally(()=>{
        this.saveLoading = false
      })
    },
    rowUpdate() {
      this.saveLoading = true
      let form = JSON.parse(JSON.stringify(this.form))
      form.memberNames = this.memberList.filter(item=>{
        return this.form.memberIds.indexOf(item.value) > -1
      }).map(item=>{
        return item.label
      }).join(',')
      // form.parentName = this.teamList.filter(item=>{
      //   return item.value === form.parentId
      // }).map(item=>{
      //   return item.label
      // }).join(',')
      form.leaderName = this.memberList.filter(item=>{
        return item.value === form.leaderId
      }).map(item=>{
        return item.label
      }).join(',')
      updateEmergencyranks(form).then(res => {
        if (res.data.code === 200) {
          this.onLoad(this.page);
          this.handleClose()
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        }
      }).finally(()=>{
        this.saveLoading = false
      })
    },
    rowDel(action, row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return removeEmergencyranks(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    },
    beforeOpen(done, type) {
      if (["edit", "view"].includes(type)) {
        getEmergencyranksDetail(this.form.id).then((res) => {
          this.form = res.data.data;
        });
      }
      done();
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done();
    },
    getuserList() {
      getuserList(1, 9999).then(res=>{
        if (res.data.code === 200) {
          this.memberList = res.data.data.records.map(item=>{
            return {
              value: item.id,
              label: item.realName
            }
          })
        }
      })
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
      if (this.$refs.crud) {
          this.$refs.crud.toggleSelection();
      }
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    onLoad(page, params = {}) {
      this.loading = true;
      getEmergencyranksList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        data.records = data.records.map(item=>{
          item.memberNumber = item.memberIds.length
          return item
        })
        this.data = data.records;
        this.loading = false;
        this.selectionClear();
      });
      // getEmergencyranksList(1, 9999).then((res) => {
      //   const data = res.data.data;
      //   this.teamList = data.records.map(item=>{
      //     return {
      //       value: item.id,
      //       label: item.name
      //     }
      //   })
      // });
    },
    handleClose() {
      this.$refs.form.resetFields()
      this.authDialog = false
      this.form = {
        parentId: '',
        name: '',
        memberIds: [],
        remark: ''
      }
    }
  },
};
</script>

<style>
::v-deep .el-card__body{
  height: 100% ;
}
</style>
